import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/:child?",
      name: "Home",
      component: () => import("../components/Home/index.vue"),
    },
    {
      path: "/:child?/:outlet_id?/:table_id?", // in case user reload page for sele
      name: "HomeQR",
      component: () => import("../components/Home/index.vue"),
    },
    {
      path: "/session/:session?",
      name: "HomeSESSION",
      component: () => import("../components/Home/index.vue"),
    },
    {
      path: "/i/:invoice_id",
      name: "Invoice",
      component: () => import("../components/Page/Invoice/index.vue"),
    },

    // ++++++++++++++++++++++++++++++++ Products start  ++++++++++++++++++++++++++++++++
    //
    {
      //Products STATIC QR
      path: "/:child?/:outlet_id?/:table_id?/products",
      name: "Products",
      component: () => import("../components/Page/Products"),
    },
    {
      //Products SHOPLINK QR
      path: "/:child?/products",
      name: "ShoplinkProducts",
      component: () => import("../components/Page/Products"),
    },
    {
      //Products SESSION QR
      path: "/session/:session?/products",
      name: "SessionProducts",
      component: () => import("../components/Page/Products"),
    },
    // ++++++++++++++++++++++++++++++++ Products end  ++++++++++++++++++++++++++++++++
    // ++++++++++++++++++++++++++++++++ Product Detail start  ++++++++++++++++++++++++++++++++
    {
      //Detail Product STATIC QR
      path: "/:child?/:outlet_id?/:table_id?/product",
      name: "ProductsDetail",
      component: () => import("../components/Page/Product"),
    },
    {
      //Detail Product SHOP LINK
      path: "/:child?/product",
      name: "ShoplinkProductsDetail",
      component: () => import("../components/Page/Product"),
    },
    {
      //Detail Product SESSION QR
      path: "/session/:session?/product",
      name: "SessionProductsDetail",
      component: () => import("../components/Page/Product"),
    },
    // ++++++++++++++++++++++++++++++++ Product Detail end  ++++++++++++++++++++++++++++++++
    {
      path: "/demo",
      name: "Demo",
      component: () => import("../views/Demo.vue"),
    },
    {
      path: "/:child?/:outlet_id?/:table_id?/user-detail",
      name: "UserDetail",
      component: () => import("../components/UserDetail/index.vue"),
    },
    {
      path: "/productDemo/:id",
      name: "ProductDemo",
      // props: true,
      component: () => import("../components/Page/ProductDemo/index.vue"),
    },
    // ++++++++++++++++++++++++++++++++ Cart start  ++++++++++++++++++++++++++++++++
    {
      // STATIC QR Cart
      path: "/:child?/:outlet_id?/:table_id?/cart",
      name: "Cart",
      component: () => import("../components/Page/Cart/index.vue"),
    },
    {
      // SHOP LINK Cart
      path: "/:child?/cart",
      name: "ShoplinkCart",
      component: () => import("../components/Page/Cart/index.vue"),
    },
    {
      // SESSION Cart
      path: "/session/:session?/cart",
      name: "SessionCart",
      component: () => import("../components/Page/Cart/index.vue"),
    },
    // ++++++++++++++++++++++++++++++++ Cart end  ++++++++++++++++++++++++++++++++

    // ++++++++++++++++++++++++++++++++ User address start  ++++++++++++++++++++++++++++++++
    {
      // STATIC QR User
      path: "/:child?/:outlet_id?/:table_id?/user",
      name: "StaticUser",
      component: () => import("../components/Page/Users/index.vue"),
    },
    {
      // SHOP LINK user
      path: "/:child?/user",
      name: "ShoplinkUser",
      component: () => import("../components/Page/Users/index.vue"),
    },
    {
      // SESSION user
      path: "/session/:session?/user",
      name: "SessionUser",
      component: () => import("../components/Page/Users/index.vue"),
    },
    // ++++++++++++++++++++++++++++++++ User address start  ++++++++++++++++++++++++++++++++

    //redirect
  ],
});
