import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "./assets/global.scss";
import firebase from "firebase/app";
import "firebase/app";
import constant from "./constant";
import VueGtag from "vue-gtag";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGtag, {
  bootstrap: false,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: constant.Google_API,
    libraries: "places",
  },
});

firebase.initializeApp(constant.config);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  created() {
    let split = window.location.href.split("/");
    console.log("split", split);
    if (split[3] == "session") {
      this.$router.push({
        name: "HomeSESSION",
        params: { session: split[4] },
      });
    } else if (split.length == 5 && split[3] == "i") {
      this.$router.push({
        name: "Invoice",
        params: { invoice_id: split[4] },
      });
    } else if (split.length == 6 || split.length == 7) {
      this.$router.push({
        name: "HomeQR",
        params: { child: split[3], outlet_id: split[4], table_id: split[5] },
      });
    } else if (split[3] == "demo") {
      this.$router.push({
        name: "Demo",
      });
    } else if (split[3] == "productDemo") {
      this.$router.push({
        name: "ProductDemo",
      });
    } else if (split.length == 4 && split[3].includes("?s=")) {
      var queryVar = split[3].replace("?s=", "");
      this.$router.push({
        name: "HomeSESSION",
        params: { session: queryVar },
      });
    } else
      this.$router.push({
        name: "Home",
        params: { child: split[3] },
      });
  },
  render: (h) => h(App),
}).$mount("#app");
