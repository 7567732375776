import vuex from "vuex";
import vue from "vue";
import routes from "./module/route-stores";
import user from "./module/user-stores";
import product from "./module/product-stores";
vue.use(vuex);

export default new vuex.Store({
  modules: {
    routes,
    user,
    product,
  },
});
