export default {
  config: {
    apiKey: "AIzaSyDe3fOhIg0cZ5LcxlSApgb02tmBd2opGQU",
    authDomain: "nextorder-auth.firebaseapp.com",
    databaseURL: "https://nextorder-auth.firebaseio.com",
    projectId: "nextorder-auth",
    storageBucket: "nextorder-auth.appspot.com",
    messagingSenderId: "415502557742",
    appId: "1:415502557742:web:deec5d5e9977826e0a35bf",
    measurementId: "G-GYD81DDRXV",
  },
  Google_API: "AIzaSyAJIU96TdlM9BblWQhm-QWkwmgjIVUVkN4",
};
